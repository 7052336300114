import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "paypal-tip-body"
};
const _hoisted_2 = {
    key: 1,
    class: "paypal-tip-body"
};
const _hoisted_3 = { class: "paypal-tip-title" };
const _hoisted_4 = { class: "paypal-tip-title-header" };
const _hoisted_5 = { class: "paypal-tip-subtitle f-inter" };
const _hoisted_6 = { class: "paypal-tip-content" };
const _hoisted_7 = { class: "paypal-tip-buttons" };
const _hoisted_8 = { class: "paypal-button-section" };
const _hoisted_9 = {
    key: 0,
    id: "paypal-button-tip"
};
const _hoisted_10 = { class: "cancel-section" };
import CurrencyInput from '@components/Core/CurrencyInput.vue';
import { DEFAULT_CURRENCY, paypalSupportedCurrencies } from '@ts/Util/currency';
import { computed, reactive, ref, watch, onMounted } from "vue";
import { loadScript } from "@paypal/paypal-js";
import { PaypalClientID } from "@ts/config";
import Loading from '@components/Util/Loading.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PayPalTip',
    props: {
        t: {
            type: Object,
            default: () => ({
                tipTitle: 'Send tip to',
                tipSubtitle: 'Show some love to your instructor by sending a tip',
                tipAmountPlaceholder: 'Enter tip amount',
                sendTip: 'Send tip',
                tipCancel: 'Cancel',
            }),
        },
        instructorName: {
            type: String,
            required: true,
        },
        instructorEmail: {
            type: String,
            required: true,
        },
        currencies: {
            type: Array,
            default: () => paypalSupportedCurrencies
        },
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        style: {
            type: Object,
            default: () => {
                return {
                    layout: 'vertical',
                    color: 'blue',
                    shape: 'rect',
                    label: 'paypal',
                    tagline: false,
                };
            }
        }
    },
    emits: [
        'closed',
        'paypal:clicked',
        'paypal:approved',
        'price:focus',
        'price:blur',
    ],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const loading = ref(true);
        const loadPaypalScript = async () => {
            try {
                const scriptOptions = {
                    'client-id': PaypalClientID,
                    'components': 'buttons,marks',
                    'enable-funding': 'venmo,card',
                    'disable-funding': 'paylater',
                    'currency': formData.currency,
                };
                const paypal = await loadScript(scriptOptions);
                return paypal;
            }
            catch (error) {
                console.error(error);
            }
            finally {
                loading.value = false;
            }
        };
        const tipHeader = computed(() => {
            return `${props.t.tipTitle} ${props.instructorName}`;
        });
        const availableCurrencies = computed(() => {
            return props.currencies.filter(currency => paypalSupportedCurrencies.includes(currency));
        });
        const formData = reactive({
            price: '',
            currency: props.currency
        });
        let buttonActions;
        const onApprove = (data, actions) => {
            return actions.order.capture().then(function (details) {
                details.paymentSource = data.paymentSource;
                emits('paypal:approved', details);
                return Promise.resolve();
            });
        };
        const createOrder = (data, actions) => {
            emits('paypal:clicked', formData.price, formData.currency);
            return actions.order.create(getOrderData());
        };
        const onInit = (data, actions) => {
            buttonActions = actions;
            buttonActions.disable();
        };
        const buttonToggle = (toggle) => {
            if (buttonActions) {
                toggle ? buttonActions.enable() : buttonActions.disable();
            }
        };
        const onCurrencyUpdate = () => {
            formData.price = '';
            paypalButtonsRender();
        };
        const getOrderData = () => {
            return {
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: `A tip for instructor ${props.instructorName}`,
                        amount: {
                            currency_code: formData.currency,
                            value: formData.price,
                            breakdown: {
                                item_total: {
                                    currency_code: formData.currency,
                                    value: formData.price
                                },
                                handling: {
                                    currency_code: formData.currency,
                                    value: '0'
                                }
                            }
                        },
                        items: [{
                                name: `A tip for instructor ${props.instructorName}`,
                                unit_amount: {
                                    currency_code: formData.currency,
                                    value: formData.price
                                },
                                quantity: '1'
                            }],
                        payee: {
                            email_address: props.instructorEmail
                        }
                    }
                ],
                application_context: {
                    shipping_preference: "NO_SHIPPING"
                }
            };
        };
        watch(() => props.currency, (newCurrency) => {
            formData.currency = newCurrency;
        });
        watch(formData, (newForm) => {
            buttonToggle(Boolean(newForm.price) && Boolean(newForm.currency));
        });
        const isValid = computed(() => {
            return Boolean(formData.price) && Boolean(formData.currency);
        });
        const paypalButtonsRender = async () => {
            try {
                const paypal = await loadPaypalScript();
                if (paypal) {
                    const buttonOptions = {
                        createOrder: createOrder,
                        onApprove: onApprove,
                        onInit: onInit,
                        style: props.style
                    };
                    const button = paypal?.Buttons?.(buttonOptions)?.render('#paypal-button-tip');
                }
            }
            catch (error) {
                console.error('Failed to load Paypal JS SDK script', error);
            }
        };
        onMounted(async () => {
            paypalButtonsRender();
        });
        const emits = __emit;
        return (_ctx, _cache) => {
            return (loading.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(Loading)
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(tipHeader.value), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(__props.t.tipSubtitle), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(CurrencyInput, {
                            price: formData.price,
                            "onUpdate:price": _cache[0] || (_cache[0] = ($event) => ((formData.price) = $event)),
                            currency: formData.currency,
                            "onUpdate:currency": [
                                _cache[1] || (_cache[1] = ($event) => ((formData.currency) = $event)),
                                onCurrencyUpdate
                            ],
                            currencies: availableCurrencies.value,
                            "price-tooltip": __props.t.tipAmountPlaceholder,
                            "reversed-order": true,
                            "onPrice:focus": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('price:focus', $event))),
                            "onPrice:blur": _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('price:blur', $event)))
                        }, null, 8, ["price", "currency", "currencies", "price-tooltip"]),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                (!loading.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("a", {
                                    class: "cancelTip",
                                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (_ctx.$emit('closed')), ["prevent"]))
                                }, _toDisplayString(__props.t.tipCancel), 1)
                            ])
                        ])
                    ])
                ]));
        };
    }
});
