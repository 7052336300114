import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import PayPalTip from "@components/Buy/PayPalTip.vue";
import { DEFAULT_CURRENCY, paypalSupportedCurrencies } from '@ts/Util/currency';
import { onMounted, reactive, ref } from "vue";
import { csrfToken } from "@ts/Util/security";
import { post } from "@ts/Util/http";
import { mixpanelServiceTrack } from '@ts/Util/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PayPalTipHandler',
    props: {
        t: {
            type: Object,
            default: () => ({
                tipTitle: 'Send tip to',
                tipSubtitle: 'Show some love to your instructor by sending a tip',
                tipAmountPlaceholder: 'Enter tip amount',
                sendTip: 'Send tip',
                tipCancel: 'Cancel',
                tipToastHeader: 'Success',
                tipToastText: 'Thank you for tipping your instructor.'
            }),
        },
        instructorPid: {
            type: String,
            default: ''
        },
        instructorName: {
            type: String,
            required: true,
        },
        currencies: {
            type: Array,
            default: () => paypalSupportedCurrencies
        },
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        storeTipUrl: {
            type: String,
            default: ''
        },
        markTipUrl: {
            type: String,
            default: ''
        },
        asyncContactRetrieval: {
            type: Boolean,
            default: true
        },
        tipDataAction: {
            type: String,
            default: ''
        },
        giftSource: {
            type: String,
            default: ''
        }
    },
    emits: [
        'closed',
        'price:focus',
        'price:blur',
        'toast:message'
    ],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const tipID = ref('');
        const tipState = reactive({
            initialized: false,
            email: '',
        });
        const handlePayPalClick = async (amount, currency) => {
            if (props.markTipUrl && props.storeTipUrl) {
                const body = {
                    pid: props.instructorPid,
                    amount: amount,
                    currency_code: currency
                };
                const response = await post(props.storeTipUrl, csrfToken(), body);
                const data = await response.json();
                if (data && data.success && data.tip_id) {
                    tipID.value = data.tip_id;
                }
            }
        };
        const handleOnApprove = async (details) => {
            const amount = details?.purchase_units[0]?.amount?.value ?? '';
            const currency = details?.purchase_units[0]?.amount?.currency_code ?? '';
            const method = details?.paymentSource ?? '';
            if (tipID.value) {
                const body = {
                    tip_id: tipID.value
                };
                const response = await post(props.markTipUrl, csrfToken(), body);
                const data = await response.json();
                if (!(data && data.success)) {
                    console.error('Failed to mark tip as done.');
                }
                else {
                    mixpanelServiceTrack('TrackSentGift', {
                        instructorName: props.instructorName,
                        giftAmount: amount,
                        currency: currency,
                        paymentMethod: method,
                        giftSource: props.giftSource
                    });
                }
            }
            emits('toast:message', {
                type: 'success',
                message: props.t?.tipToastText,
                title: props.t?.tipToastHeader
            });
            emits('closed');
        };
        const redirect = function (url) {
            window.location.href = url.toString();
        };
        onMounted(async () => {
            if (!props.asyncContactRetrieval || !props.tipDataAction) {
                return;
            }
            try {
                const tdResp = await post(props.tipDataAction, csrfToken());
                if (tdResp.status !== 200) {
                    throw new Error();
                }
                const tdRespJson = await tdResp.json();
                if (!tdRespJson?.paypal_email) {
                    throw new Error();
                }
                tipState.email = tdRespJson.paypal_email;
                tipState.initialized = true;
            }
            catch (e) {
                console.error(e);
            }
        });
        const emits = __emit;
        return (_ctx, _cache) => {
            return (!__props.asyncContactRetrieval || tipState.initialized)
                ? (_openBlock(), _createBlock(PayPalTip, {
                    key: 0,
                    t: __props.t,
                    "instructor-name": __props.instructorName,
                    "instructor-email": tipState.email,
                    currencies: __props.currencies,
                    currency: __props.currency,
                    onClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('closed'))),
                    "onPaypal:clicked": handlePayPalClick,
                    "onPaypal:approved": handleOnApprove,
                    "onPrice:focus": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('price:focus', $event))),
                    "onPrice:blur": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('price:blur', $event)))
                }, null, 8, ["t", "instructor-name", "instructor-email", "currencies", "currency"]))
                : _createCommentVNode("", true);
        };
    }
});
