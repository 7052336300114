import { defineComponent, reactive, watch, ref } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import StarSvg from '@bx-icons/regular/bx-star.svg';
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg';
const localT = {
    modalTitle: 'How was your class with {0}?',
};
export default defineComponent({
    name: 'Rating',
    components: {
        'star-icon': StarSvg,
        'star-filled-icon': StarFilledSvg,
        // 'star-half-filled-icon': StarHalfFilledSvg,
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        /**
         * Used by vue to bind the input value to `v-model` set on the component.
         */
        modelValue: {
            type: Number,
            default: 0,
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        maxRating: {
            type: Number,
            default: 5,
        },
        starSize: {
            type: Number,
            default: 24,
        },
        starColor: {
            type: String,
            default: 'yellow'
        }
    },
    emits: [
        'rating:rated',
        'rating:hovered',
        'rating:hovered-out',
    ],
    setup(props, ctx) {
        const state = reactive({
            currentRate: props.modelValue,
            hoverRate: 0,
        });
        watch(() => props.modelValue, (newValue) => {
            state.currentRate = newValue;
        });
        let isHovering = ref(false);
        const isSelected = (index) => {
            return index <= state.currentRate;
        };
        const isHovered = (starRate) => {
            return starRate <= state.hoverRate;
        };
        const onStarHoverHandler = (starRate) => {
            if (props.readOnly) {
                return;
            }
            isHovering.value = true;
            state.hoverRate = starRate;
            ctx.emit('rating:hovered', starRate);
        };
        const onStarHoverOutHandler = (starRate) => {
            if (props.readOnly) {
                return;
            }
            isHovering.value = false;
            state.hoverRate = 0;
            ctx.emit('rating:hovered-out');
        };
        const onStarRateClickHandler = (starRate) => {
            if (props.readOnly) {
                return;
            }
            state.currentRate = starRate;
            ctx.emit('rating:rated', starRate);
        };
        return {
            state,
            isHovering,
            isSelected,
            isHovered,
            onStarHoverHandler,
            onStarHoverOutHandler,
            onStarRateClickHandler,
        };
    },
});
