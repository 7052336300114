import { defineComponent, reactive, computed, onMounted, watch } from 'vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
import { theme, ThemeType } from "@ts/Util/theme";
import CloseSvg from '@bx-icons/regular/bx-x.svg';
import CheckSvg from '@bx-icons/regular/bx-check.svg';
import InfoSvg from "@bx-icons/regular/bx-info-circle.svg";
const toastPositions = {
    topLeft: 'top-left',
    topCenter: 'top-center',
    topRight: 'top-right',
    bottomLeft: 'bottom-left',
    bottomCenter: 'bottom-center',
    bottomRight: 'bottom-right',
    middleLeft: 'middle-left',
    middleCenter: 'middle-center',
    middleRight: 'middle-right',
    standard: 'standard'
};
const toastTypes = ['info', 'warning', 'success', 'danger'];
export default defineComponent({
    name: 'Toast',
    components: { CloseSvg, CheckSvg, InfoSvg },
    props: {
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        // if 0, Toast will not auto-hide
        timeout: {
            type: [Number, String],
            default: 0
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        userDismissable: {
            type: Boolean,
            default: true
        },
        cta: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'info',
            validate: (value) => {
                return toastTypes.includes(value);
            }
        },
        customStyles: {
            type: Object,
            default: null
        },
        /**
         * Alternate positions string prop
         */
        position: {
            type: String,
            default: '',
            validate: (value) => {
                return Object.values(toastPositions).includes(value);
            }
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topLeft: {
            type: Boolean,
            default: false
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topCenter: {
            type: Boolean,
            default: false
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topRight: {
            type: Boolean,
            default: false
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomLeft: {
            type: Boolean,
            default: false
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomCenter: {
            type: Boolean,
            default: false
        },
        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomRight: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 1,
            validate: (val) => {
                return val >= 0 && val <= 1;
            }
        },
        noBoxShadow: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'opening',
        'opened',
        'closing',
        'closed',
        'cta:click'
    ],
    setup(props, { emit }) {
        const isMobile = isMobileReactive();
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        let timeout;
        const state = reactive({
            queuedToShow: props.isVisible,
            toShow: false,
            positionClasses: computed(() => {
                const classes = [];
                if (props.position) {
                    const position = props.position === toastPositions.standard
                        ? (isMobile.value ? toastPositions.topCenter : toastPositions.bottomLeft)
                        : props.position;
                    classes.push(position);
                }
                else {
                    const posProps = Object.keys(toastPositions);
                    const position = posProps.filter(key => props[key]);
                    if (position.length) {
                        classes.push(toastPositions[position[0]]);
                    }
                    else {
                        classes.push('top-left');
                    }
                }
                return classes;
            }),
        });
        const cssVars = computed(() => {
            let css = {};
            css['--toast-opacity'] = props.opacity;
            if (props.noBoxShadow) {
                css['box-shadow'] = 'none';
            }
            if (props.customStyles) {
                Object.keys(props.customStyles).forEach(style => {
                    css = {
                        ...css,
                        ...props.customStyles[style]
                    };
                });
            }
            return css;
        });
        // Set initial state after mount so it still animates in/out
        onMounted(() => {
            if (state.queuedToShow) {
                state.toShow = props.isVisible;
            }
        });
        // kickstart automatic dismissal when `state.toShow` is updated (either internally or externally)
        watch(() => state.toShow, (toShow) => {
            if (toShow && props.timeout) {
                // Auto-hide when timeout prop is present
                dismiss(props.timeout);
            }
            else if (!toShow && !timeout) {
                // dismiss immediately
                dismiss();
            }
        });
        // update internal state when external prop changes
        watch(props, ({ isVisible }) => {
            state.toShow = isVisible;
        });
        /**
         * Hide the toast. If time is provided it will dismiss after time has elapsed
         */
        const dismiss = (time = 0) => {
            clearInterval(timeout);
            timeout = setTimeout(() => {
                state.toShow = false;
            }, +time);
        };
        /**
         * Show the toast
         */
        const show = () => {
            state.toShow = true;
        };
        /**
         * Handle the default CTA click
         */
        const handleCtaClick = () => {
            // provide a handler to the consumer of the event to dismiss this modal
            emit('cta:click', { dismiss });
        };
        /**
         * If user mouses over the Toast, stop the auto-hide timeout
         */
        const handleMouseover = () => {
            clearInterval(timeout);
        };
        /**
         * When user mouses out of the toast, restart the auto-hide timeout
         */
        const handleMouseout = () => {
            if (props.timeout) {
                return dismiss(props.timeout);
            }
        };
        /**
         * Handle when the toast is transitioning open/close
         */
        const handleTransitionstart = (event) => {
            if (event.propertyName == 'transform') {
                emit(state.toShow ? 'opening' : 'closing');
            }
        };
        /**
         * Handle when the toast is actually not visible (transition complete)
         */
        const handleTransitionend = (event) => {
            if (event.propertyName == 'transform') {
                emit(state.toShow ? 'opened' : 'closed');
            }
        };
        return {
            state,
            dismiss,
            show,
            handleCtaClick,
            handleMouseover,
            handleMouseout,
            handleTransitionend,
            handleTransitionstart,
            cssVars,
            isMobile,
            isZumbaThemed
        };
    }
});
