// A guess at the height of the virtual keyboard. Could determine this based on the user agent, but hard-coded for now:
const KEYBOARD_HEIGHT = 250; // pixels
// Wait this long for Safari to adjust the scroll position:
const SCROLL_WAIT = 350; // milliseconds
export function useSafariMobileKeyboard() {
    const scrollTextIntoView = () => {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            // Capture the current scroll position.
            const currentScrollPosition = window.scrollY;
            setTimeout(() => {
                if (window.scrollY === currentScrollPosition) {
                    // Safari did not scroll the page, so we assume the keyboard is now open, Safari
                    // has no plan to scroll itself, and we have to offset the scroll position ourselves.
                    // We do the offset even if a hardware keyboard is connected and the virtual keyboard
                    // doesn't actually display.
                    const newPos = window.scrollY + KEYBOARD_HEIGHT;
                    window.scrollTo({ top: newPos });
                }
            }, SCROLL_WAIT);
        }
    };
    return {
        scrollTextIntoView,
    };
}
