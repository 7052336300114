import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "icon-container"
};
const _hoisted_2 = {
    key: 0,
    class: "icon-warning"
};
const _hoisted_3 = {
    key: 1,
    class: "icon-success badge"
};
const _hoisted_4 = {
    key: 2,
    class: "icon-danger badge"
};
const _hoisted_5 = {
    key: 3,
    class: "icon-info badge"
};
const _hoisted_6 = { class: "toast-title-wrapper msg-container" };
const _hoisted_7 = {
    key: 0,
    class: "z-b3 title"
};
const _hoisted_8 = { class: "cta-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CloseSvg = _resolveComponent("CloseSvg");
    const _component_CheckSvg = _resolveComponent("CheckSvg");
    const _component_InfoSvg = _resolveComponent("InfoSvg");
    return (_openBlock(), _createBlock(_Transition, { name: "reveal" }, {
        default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["toast-container", { [`${_ctx.state.positionClasses}`]: true, [`${_ctx.type}`]: true }]),
                style: _normalizeStyle([_ctx.cssVars]),
                onMouseover: _cache[2] || (_cache[2] =
                    //@ts-ignore
                    (...args) => (_ctx.handleMouseover && _ctx.handleMouseover(...args))),
                onMouseout: _cache[3] || (_cache[3] =
                    //@ts-ignore
                    (...args) => (_ctx.handleMouseout && _ctx.handleMouseout(...args))),
                onTransitionstart: _cache[4] || (_cache[4] =
                    //@ts-ignore
                    (...args) => (_ctx.handleTransitionstart && _ctx.handleTransitionstart(...args))),
                onTransitionend: _cache[5] || (_cache[5] =
                    //@ts-ignore
                    (...args) => (_ctx.handleTransitionend && _ctx.handleTransitionend(...args)))
            }, [
                (_ctx.userDismissable)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "dismiss-btn",
                        onClick: _cache[0] || (_cache[0] = () => _ctx.dismiss())
                    }, [
                        _createVNode(_component_CloseSvg)
                    ]))
                    : _createCommentVNode("", true),
                (!_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "icon", {}, () => [
                            (_ctx.type == 'warning')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[6] || (_cache[6] = [
                                    _createElementVNode("div", { class: "triangle" }, null, -1),
                                    _createElementVNode("span", { class: "icon__inner" }, "!", -1)
                                ])))
                                : (_ctx.type == 'success')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(_component_CheckSvg)
                                    ]))
                                    : (_ctx.type == 'danger')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createVNode(_component_CloseSvg)
                                        ]))
                                        : (_ctx.type == 'info')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                                _createVNode(_component_InfoSvg)
                                            ]))
                                            : _createCommentVNode("", true)
                        ], true)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                    (_ctx.$slots.title || _ctx.title)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                            _renderSlot(_ctx.$slots, "title", {}, () => [
                                _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ], true)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("p", {
                        class: _normalizeClass(["z-b5 message", {
                                'no-title': !_ctx.$slots.title,
                                'f-inter': _ctx.isZumbaThemed
                            }])
                    }, [
                        _renderSlot(_ctx.$slots, "message", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.message), 1)
                        ], true)
                    ], 2)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    (_ctx.$slots.cta)
                        ? _renderSlot(_ctx.$slots, "cta", { key: 0 }, undefined, true)
                        : (_ctx.cta && !_ctx.$slots.cta)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                class: "button magneto minion cta-btn",
                                onClick: _cache[1] || (_cache[1] =
                                    //@ts-ignore
                                    (...args) => (_ctx.handleCtaClick && _ctx.handleCtaClick(...args)))
                            }, _toDisplayString(_ctx.cta), 1))
                            : _createCommentVNode("", true)
                ])
            ], 38), [
                [_vShow, _ctx.state.toShow]
            ])
        ]),
        _: 3
    }));
}
