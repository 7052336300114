import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "rating" };
const _hoisted_2 = { class: "sr-only" };
const _hoisted_3 = ["onClick", "onMouseover", "onMouseout"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_star_icon = _resolveComponent("star-icon");
    const _component_star_filled_icon = _resolveComponent("star-filled-icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "screen-reader", {
                rating: _ctx.state.currentRate,
                stars: _ctx.maxRating
            }, () => [
                _createElementVNode("span", null, "Rated " + _toDisplayString(_ctx.state.currentRate) + " stars out of " + _toDisplayString(_ctx.maxRating), 1)
            ], true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxRating, (n) => {
            return (_openBlock(), _createElementBlock("span", {
                key: n,
                class: _normalizeClass([
                    'rating__star',
                    {
                        'rating__star--pointer': !_ctx.readOnly,
                        'rating__star--selected': !_ctx.isHovering && _ctx.isSelected(n),
                        'rating__star--hovered': _ctx.isHovered(n),
                    },
                ]),
                onClick: ($event) => (_ctx.onStarRateClickHandler(n)),
                onMouseover: ($event) => (_ctx.onStarHoverHandler(n)),
                onMouseout: ($event) => (_ctx.onStarHoverOutHandler(n))
            }, [
                _createVNode(_component_star_icon, {
                    class: "rating__star__icon",
                    width: _ctx.starSize,
                    height: _ctx.starSize
                }, null, 8, ["width", "height"]),
                _createVNode(_component_star_filled_icon, {
                    class: _normalizeClass(["rating__star__icon--filled", {
                            [`rating__star__icon--${_ctx.starColor}`]: true
                        }]),
                    width: _ctx.starSize,
                    height: _ctx.starSize
                }, null, 8, ["class", "width", "height"])
            ], 42, _hoisted_3));
        }), 128))
    ]));
}
